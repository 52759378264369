//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n';

import { BranchModel } from '@/modules/branch/branch-model';
import { FormSchema } from '@/shared/form/form-schema';

const { fields } = BranchModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.address,
  fields.cityId,
  fields.phoneNumber,
  fields.companyId
]);

export default {
  name: 'app-branch-form-modal',

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    // values: {
    //   type: Array,
    //   required: true,
    // },
  },

  data() {
    return {
      language: getLanguageCode(),
      rules: formSchema.rules(),
      model: null,
      citiesOptions: [],
      popupShow: false,
      nameEn: '',
      branchCity: '',
      branchNumber: '',
      phoneNumber: null,
      TRansObject: {
          countrySelectorLabel: 'Code Country',
          countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: 'Phone Number',
          example: 'Example :',
      },
      TRansObjectAr: {
          countrySelectorLabel: 'كود البلد',
          countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: 'رقم الجوال',
          example: 'مثال :',
      },
      CCode:'',
      isCCode:'',
    };
  },
  components:{
    VuePhoneNumberInput
  },
  watch:{
    model(newval, oldval){
      console.log('newval ', newval);
      console.log('oldval ',oldval);
    }
  },
  computed: {
    ...mapGetters({
      record: 'branch/form/record',
      findLoading: 'branch/form/findLoading',
      is_screen_xs: 'layout/is_screen_xs',
      citiesRows: 'city/list/rows',
      loadingCities: 'city/list/loading',
      placesRows: 'place/list/rows',
      loadingPlaces: 'place/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      saveLoading: 'branch/form/saveLoading'
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    cities() {
      return this.citiesRows.map((item) => {
        return {
          value: item.id,
          label: item.name[this.language],
        };
      });
    },
    isRTL(){
            return i18n.locale == 'ar'
        }
    // placesList() {
    //   return this.placesRows
    // },
  },

  async created() {
    this.model = formSchema.initialValues(this.record);
    this.model.name = this.model.name || { en: undefined, ar: undefined }
    this.model.address = this.model.address || { en: undefined, ar: undefined}
  },
  async mounted() {
    await this.doFetchCities()
  },

  methods: {
    ...mapActions({
      doFind: 'plan/form/doFind',
      doNew: 'plan/form/doNew',
      doUpdate: 'plan/form/doUpdate',
      doCreate: 'branch/form/doCreate',
      doFetchCities: 'city/list/doFetch',
      doFetchPlaces: 'place/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.citiesOptions = this.cities;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.citiesOptions = this.cities.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1,
        );
      });
    },

    onUpdate(data) {
            this.model.phoneNumber = data.formatInternational
        },

    doClear() {
      this.selected_cities = [];
    },
    handleAddBranch() {
      console.log('SUBMITTED');
      const newBranch = {
        branchName: this.branchName,
        phoneNumber: this.branchNumber,
        branchCity: this.branchCity,
        createdAt: Date.now(),
      };
      this.values.push(newBranch);
      console.log(newBranch);
      // this.dialogVisible=false
      this.$emit('branchCreated');
    },

    async doSubmit() {
      this.model.companyId = this.currentUser.companyId
      console.log(this.model);
      let record = await this.doCreate(this.model);
      this.$emit('branchCreated')
      return record;      
    },
  },
};
